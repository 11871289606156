<template>
  <main>
    <header><h2>Create Tutorial</h2></header>
    <section class="bodyContainer">
      <form>
        <p>Tutorial Details</p>

        <label>VIDEO TITLE</label>
        <input type="text" placeholder="Video Title" />

        <label>VIDEO URL</label>
        <input type="text" placeholder="Youtube URL" />
        <label>DESCRIPTION</label>
        <textarea></textarea>
        <button>Submit</button>
      </form>
    </section>
  </main>
</template>
<script>
export default {
  name: "TutorialForm",
};
</script>
<style lang='scss' scoped>
@import "@/styles/variables";

main {
  margin-top: 4rem;
  width: 30%;
  height: 100%;

  & header {
    width: 100%;
    color: white;
    font-family: $primaryFont;
    font-size: 1rem;
    border-bottom: $borderBottom;
  }

  & .bodyContainer {
    width: 100%;
    height: 20rem;
    margin-top: 1rem;
  }
}

form {
  background: $primaryColor;
  width: 100%;
  height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: $border;

  & p {
    font-family: $primaryFont;
    font-size: 1.25rem;
    font-weight: 600;
    margin: 1rem 0 4rem 0;
    color: white;
  }

  & label {
    width: 80%;
    margin: 1rem 0 0.25rem 0;
    color: $secondaryFontColor;
    font-size: 1rem;
  }

  & input {
    width: 80%;
    height: 2rem;
    border-radius: 2px;
    outline: none;
    border: none;
    padding: 0 1rem;
    font-size: 1rem;
    background: $inputBackground;
  }

  & textarea {
    width: 80%;
    height: 6rem;
    border-radius: 2px;
    outline: none;
    border: none;
    padding: 1rem;
    font-size: 1rem;
    resize: none;
  }

  & button {
    width: 80%;
    padding: 0.5rem 1rem;
    background: $green;
    margin: 1rem 0;
    outline: none;
    border: none;
    color: white;
    border-radius: 2px;
  }
}
</style>